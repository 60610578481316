import React from "react"
import Hero from "../components/heroPost"
import { graphql } from "gatsby"
import Seo from "../components/seoPost"
import Blog from "../schema/blogPosting"
import Bread from "../components/breadPost"
import { GatsbyImage } from "gatsby-plugin-image"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      timeToRead
      fields {
        slug
      }
      frontmatter {
        kategori
        title
        tags
        keyword
        date(formatString: "MMM, DD yyy")
        modi(formatString: "MMM, DD yyy")
        diskripsi
        gambar {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
        }
      }
      excerpt
      html
    }
  }
`

const Template = props => {
  return (
    <main>
      <Seo
        title={props.data.markdownRemark.frontmatter.title}
        slug={props.data.markdownRemark.fields.slug}
        imageUrl={props.data.markdownRemark.frontmatter.gambar.publicURL}
        diskripsi={props.data.markdownRemark.frontmatter.diskripsi}
        tags={props.data.markdownRemark.frontmatter.keyword}
      />

      <article>
        <Hero
          tags={props.data.markdownRemark.frontmatter.tags}
          date={props.data.markdownRemark.frontmatter.date}
          kategori={props.data.markdownRemark.frontmatter.kategori}
          title={props.data.markdownRemark.frontmatter.title}
        />
        <Bread
          url2="/peliharaan/"
          nama2="peliharaan"
          nama3={props.data.markdownRemark.frontmatter.title}
        />
        <div className="md:mx-8 mx-2 lg:w-1/2 lg:mx-auto">
          <GatsbyImage
            image={
              props.data.markdownRemark.frontmatter.gambar.childImageSharp
                .gatsbyImageData
            }
            alt={props.data.markdownRemark.frontmatter.title}
            className="mt-3 border-2 border-green-200 overflow-hidden rounded-xl lg:rounded-2xl "
          />
          <div>
            <p className="text-xs text-gray-400 mt-1 text-right mx-8 ">
              Last Updated: {props.data.markdownRemark.frontmatter.modi}
            </p>
          </div>
        </div>

        <div
          className="prose md:prose-lg lg:prose-xl md:mx-auto font-semibold mx-4 prose-green my-8"
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        />
      </article>
      <Blog
        url={`https://hajebo.com${props.data.markdownRemark.fields.slug}`}
        title={props.data.markdownRemark.frontmatter.title}
        modi={props.data.markdownRemark.frontmatter.modi}
        date={props.data.markdownRemark.frontmatter.date}
        gambarUrl={`https://hajebo.com${props.data.markdownRemark.frontmatter.gambar.publicURL}`}
        baca={props.data.markdownRemark.timeToRead}
        kategori="peliharaan"
      />
    </main>
  )
}

export default Template
