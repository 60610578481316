import { BlogPosting } from "schema-dts"
import React from "react"
import { JsonLd } from "react-schemaorg"

export function Artikel({ title, url, modi, date, gambarUrl, baca, kategori }) {
  return (
    <JsonLd<BlogPosting>
      item={{
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "@id": url,
        articleSection: kategori,
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        inLanguage: "id",
        headline: title,
        thumbnailUrl: gambarUrl,
        timeRequired: baca,
        image: [gambarUrl],
        datePublished: date,
        dateModified: modi,
        author: {
          "@type": "Person",
          name: "Hajebo",
        },
        publisher: {
          "@type": "Organization",
          name: "Hajebo.com",
          logo: {
            "@type": "ImageObject",
            url: "https://hajebo.com/logo.png",
          },
        },
      }}
    />
  )
}

export default Artikel
