import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Hero = ({ tags, kategori, date, title }) => {
  const data = useStaticQuery(graphql`
    query {
      profil: file(relativePath: { eq: "hajebo-logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)
  return (
    <div className="bg-green-500 rounded-br-2xl lg:rounded-br-3xl lg:pb-16">
      <h1 className="font-serif font-semibold text-4xl lg:text-6xl text-white mx-8 md:mx-12 lg:mx-24 leading-snug py-10">
        {title}
      </h1>
      <div className="flex-row flex mb-4 mx-3.5 md:ml-12 lg:mb-12 lg:ml-24">
        <GatsbyImage
          image={data.profil.childImageSharp.gatsbyImageData}
          alt="foto profil hajebo"
          loading="eager"
          className="w-1/5 m-0.5 md:m-8 md:w-20 border-2 md:border-4 border-green-700 rounded-full overflow-hidden"
        />
        <div className="w-2/3 my-auto mx-3">
          <p className="text-xl lg:text-3xl text-white font-serif font-semibold">
            Hajebo
          </p>
          <p className="text-sm lg:text-xl font-bold  text-green-50">{date}</p>
        </div>
        <Link to={`/${kategori}/`}>
          <button className="m-2 px-2 lg:text-xl  rounded capitalize bg-blue-300 text-blue-500 border-white border-2 font-bold">
            {kategori}
          </button>
        </Link>
      </div>

      <div className="flex-row pt-0.5 mx-8 pb-8 flex flex-wrap">
        <p className="pt-1 text-white lg:text-lg lg:ml-10  font-bold text-sm">
          Tags:
        </p>
        {tags.map((tag, i) => [
          <p
            className="bg-green-300 px-2 capitalize rounded font-medium text-xs lg:text-sm text-white m-1 border-2 border-white "
            key={i}
          >
            {tag}
          </p>,
        ])}
      </div>
    </div>
  )
}

export default Hero
