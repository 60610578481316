import React from "react"
import { Link } from "gatsby"
import Breadcrumbs from "../schema/breadcrumb3"
const Bread = ({ url2, nama2, nama3 }) => {
  return (
    <ol
      aria-label="breadcrumbs"
      className="flex flex-row text-xs mt-4 lg:my-8 md:mx-20 lg:mx-56 mb-8 mx-2 flex-wrap"
    >
      <li className=" my-2 border-2 md:text-lg lg:rounded-lg lg:px-3 lg:py-1 text-green-500 font-medium border-green-300 bg-green-50 rounded px-1 mx-1">
        <Link to="/">Home</Link>
      </li>
      <span className="my-2 md:text-lg lg:text-xl">&gt;</span>
      <li className=" my-2 border-2 md:text-lg lg:rounded-lg lg:px-3 lg:py-1 text-green-500 font-medium border-green-300 bg-green-50 rounded px-1 mx-1">
        <Link to={url2}>{nama2}</Link>
      </li>
      <span className="my-2 md:text-lg lg:text-xl">&gt;</span>
      <li className="my-2 border-2 md:text-lg lg:rounded-lg lg:px-3 lg:py-1 text-green-50 font-medium border-green-700 bg-green-400 rounded px-1 mx-1">
        {nama3}
      </li>
      <Breadcrumbs
        name2={nama2}
        name3={nama3}
        url2={`https://hajebo.com${url2}`}
      />
    </ol>
  )
}

export default Bread
